
import IProduct from '@/interfaces/IProduct';
import IProductDto from '@interfaces/IProductDto';
import badgeAdapter from './badgeAdapter';

const productAdapter = (product: IProductDto): IProduct => {
  const {
    id,
    sku,
    alias,
    slug,
    nameEs,
    nameEn,
    descEn,
    descEs,
    stepper,
    decimalPoints,
    priority,
    barCode,
    useFolio,
    publicationDate,
    publicationDeadlineDate,
    saleFormat,
    unit,
    attributeOptions,
    productImgs,
    tags,
    internalTags,
    productAttachments,
    badges,
  } = product;

  return {
    id: String(id),
    sku,
    stepper,
    decimalPoints,
    name: {
      en: nameEn,
      es: nameEs,
    },
    desc: {
      en: descEn,
      es: descEs,
    },
    barCode,
    useFolio,
    publicationDate,
    publicationDeadlineDate,
    saleFormat,
    alias,
    slug,
    priority,
    unit: {
      id: +unit.id,
      code: unit.code,
      labelEn: unit.labelEn,
      labelEs: unit.labelEs,
      isActive: unit.isActive,
    },
    tags,
    internalTags,
    productAttachments,
    imgs: productImgs,
    badges: badges?.length > 0 
      ? badges.map(badgeAdapter) 
      : [],
  };
};

export default productAdapter;
