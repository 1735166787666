import { Tag } from 'antd';
import i18next from 'i18next';
import React from 'react';
import useCustomTranslation from '@/hooks/useCustomTranslation';
import Visibility from '@/enums/VisibilityRefactor';

interface VisibilityTagProps {
    visibility: Visibility | string
}
 
const VisibilityTag = ({ visibility }:VisibilityTagProps) => {
    const lang = i18next.language;

    const { t } = useCustomTranslation();

  
    let color = 'default';
    let message = '';
    if (visibility === Visibility.Always) { color = 'success'; message = t('g.always'); }
    if (visibility === Visibility.WithStock) { color = 'default'; message = t('g.with_stock'); }
    if (visibility === Visibility.Hidden) { color = 'error'; message = t('g.never'); }
  
    return (
      <Tag color={color}>{message}</Tag>
    );
  };
export default VisibilityTag;
